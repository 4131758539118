<template>
    <b-modal
      id="modal-create-product"
      size="lg"
      centered
      hide-footer
      hide-header-close
      header-class="border-0 pb-0"
      content-class="pt-0"
    >
      <!-- Custom Header -->
      <template #modal-header>
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h5 class="modal-title">Upload Product Images</h5>
            <button 
              type="button" 
              class="close"
              @click="$bvModal.hide('modal-create-product')"
            >
              <span>&times;</span>
            </button>
          </div>
          <!-- <p class="text-muted mb-0">Add your product details below</p> -->
        </div>
      </template>
  
      <!-- Modal Body -->
      <div class="px-2">
        <b-form @submit.prevent="handleSubmit">
          <b-form-group
            label="Product Name*"
            label-for="product-name"
            label-class="font-weight-bold"
          >
            <b-form-input
              id="product-name"
              v-model="form.title"
              required
              placeholder="Enter a unique product name, ex: blue bottle with white cap"
              class="form-control-lg"
            />
          </b-form-group>
  
          <b-form-group
            label="Product Type*"
            label-for="product-type"
            label-class="font-weight-bold"
          >
            <b-form-select
              id="product-type"
              v-model="form.productType"
              required
              :options="productTypes"
              class="form-control-lg"
            />
          </b-form-group>
  
          <b-form-group
            label="Description"
            label-for="product-description"
            label-class="font-weight-bold"
          >
            <b-form-textarea
              id="product-description"
              v-model="form.description"
              placeholder="Enter product description"
              rows="2"
              class="form-control-lg"
            />
          </b-form-group>
  
          <!-- Image Upload Section -->
  <b-form-group
    label="Product Images (At least 5 images)*"
    label-class="font-weight-bold"
  >
    <div 
      class="upload-area p-4 text-center border rounded mb-3"
      @drop.prevent="handleFileDrop"
      @dragover.prevent
      @dragenter.prevent
    >
      <input
        type="file"
        ref="fileInput"
        multiple
        accept="image/jpeg, image/webp, image/png, image/heic, image/heif"
        class="d-none"
        @change="handleNewImages"
      />
      <div @click="$refs.fileInput.click()" class="cursor-pointer">
        <feather-icon icon="UploadCloudIcon" size="48" class="text-primary mb-2"/>
        <p class="mb-1">Drop your images here or click to browse</p>
        <p class="text-muted small">Supported formats: JPEG, PNG, WEBP, HEIC</p>
      </div>
    </div>

    <!-- Image Previews -->
    <div v-if="form.images.length" class="image-preview-container">
      <div class="d-flex flex-wrap">
        <div 
          v-for="(image, index) in form.images" 
          :key="image.id"
          class="image-preview-wrapper"
        >
          <div class="image-preview">
            <img :src="image.url" :alt="image.alt"/>
            <div class="image-preview-overlay">
              <button 
                type="button" 
                class="btn btn-sm btn-danger"
                @click="removeImage(index)"
              >
                <feather-icon icon="TrashIcon" size="14"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form-group>
  <b-alert 
  show 
  class="mt-2 mb-6 bg-blue-50 border-0"
  style="background-color: #f0f7ff;"
>
  <div class="text-dark">
    <p class="font-medium mr-1 mb-0">📸 Photo Requirements:</p>
    <ul class="list-none pl-2 space-y-1">
      <li>At least 5 images needed. Use only clear images focused on your product.</li>
      <li>Include different angles: front, back, sides, etc.</li>  
      <li>Phone camera photos preferred for best results. Use uploader to directly add photos from your phone.</li>
    </ul>
  </div>
</b-alert>
  
          <!-- Action Buttons -->
          <div class="modal-footer border-top pt-3">
            <b-button
              variant="outline-secondary"
              @click="$bvModal.hide('modal-create-product')"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!isFormValid"
              @click="handleSubmit"
            >
              Create Product
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import heic2any from 'heic2any';
import loadImage from 'blueimp-load-image';
import {
BModal,
BForm,
BFormGroup,
BFormInput,
BFormFile,
BFormCheckbox,
BButton,
BProgress,
BFormSelect,
BFormTextarea,
BAlert
} from 'bootstrap-vue';
export default {
 name: 'CreateProductModal',
 components: {
    BAlert,
    BFormSelect,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BButton,
    BProgress,
    BFormTextarea 
  },
 data() {
   return {
     form: {
       title: '',
       productType: '',
       description: '',
       images: []
     },
     productTypes: [
     { value: null, text: 'Please select', disabled: true },
   { value: 'fashion', text: 'Fashion & Accessories' },
   { value: 'beauty', text: 'Beauty & Personal Care' },
   { value: 'food', text: 'Food & Beverages' },
   { value: 'health', text: 'Health & Wellness' },
   { value: 'furniture', text: 'Furniture & Home Decor' },
   { value: 'technology', text: 'Technology & Gadgets' },
   { value: 'toys', text: 'Toys & Baby Products' },
   { value: 'automotive', text: 'Automotive & Accessories' },
   { value: 'books', text: 'Books & Stationery' },
   { value: 'sports', text: 'Sports & Outdoors' },
   { value: 'pets', text: 'Pets & Pet Care' },
   { value: 'appliances', text: 'Home Appliances' },
   { value: 'seasonal', text: 'Seasonal & Festival Items' },
   { value: 'product', text: 'Other' }
     ]
   }
 },
 computed: {
   isFormValid() {
     return this.form.title && this.form.productType && this.form.images.length > 4;
   }
 },
 methods: {
    handleFileDrop(event) {
      const files = event.dataTransfer.files;
      this.handleNewImages({ target: { files } });
    },

    async handleNewImages(event) {
      const files = event.target.files;
      if (!files) return;
      
      for (const file of files) {
        try {
          const processedImage = await this.processImage(file);
          this.form.images.push({
            id: Date.now() + Math.random(),
            url: processedImage.dataUrl,
            file: processedImage.file,
            alt: file.name
          });
        } catch (error) {
          console.error('Error processing image:', error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error processing image ${file.name}`,
              variant: 'danger'
            }
          });
        }
      }
      this.$refs.fileInput.value = '';
    },

    async processImage(file) {
      try {
        let processedFile = file;

        // Convert HEIC/HEIF to JPEG if necessary
        if (
          file.type === 'image/heic' ||
          file.type === 'image/heif' ||
          file.name.toLowerCase().endsWith('.heic') ||
          file.name.toLowerCase().endsWith('.heif')
        ) {
          const conversionResult = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.8,
          });

          processedFile = Array.isArray(conversionResult) ? conversionResult[0] : conversionResult;
          processedFile = new File([processedFile], file.name.replace(/\.[^/.]+$/, '') + '.jpg', {
            type: 'image/jpeg',
            lastModified: file.lastModified
          });
        }

        // Create a promise to handle the image processing
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onload = (e) => {
            loadImage(
              e.target.result,
              (canvas) => {
                if (canvas.type === 'error') {
                  reject(new Error('Failed to process image'));
                  return;
                }

                const dataUrl = canvas.toDataURL(processedFile.type);
                resolve({
                  dataUrl,
                  file: processedFile
                });
              },
              {
                orientation: true,
                canvas: true,
                maxWidth: 1000,
                maxHeight: 1000,
              }
            );
          };

          reader.onerror = () => reject(new Error('Failed to read file'));
          reader.readAsDataURL(processedFile);
        });
      } catch (error) {
        throw new Error(`Image processing failed: ${error.message}`);
      }
    },

    // Updated removeImage method
    removeImage(index) {
      this.form.images.splice(index, 1);
    },

   handleSubmit() {
     if (!this.isFormValid) return;
     
     this.$emit('submit', this.form);
     this.$bvModal.hide('modal-create-product');
     this.resetForm();
   },

   resetForm() {
     this.form = {
       title: '',
       productType: 'null',
       description: '',
       images: []
     };
     if (this.$refs.fileInput) {
    this.$refs.fileInput.value = '';
  }  
   }
 }
}
</script>
  
  <style scoped>
  .modal-title {
    font-size: 1.2rem;
    font-weight: 400;
  }
  
  .upload-area {
    border: 2px dashed #dee2e6 !important;
    border-radius: 8px;
    background: #f8f9fa;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .upload-area:hover {
    border-color: #007bff !important;
    background: #f1f7ff;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .image-preview-container {
    margin-top: 1rem;
  }
  
  .image-preview-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 1rem 1rem 0;
  }
  
  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-preview-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-preview:hover .image-preview-overlay {
    opacity: 1;
  }
  
  .form-control-lg {
    font-size: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }
  
  .modal-footer {
    padding: 1rem 0 0 0;
  }
  
  .btn {
    padding: 0.5rem 1.5rem;
    font-weight: 500;
  }
  
  /* Responsive styles */
  @media (max-width: 576px) {
    .image-preview-wrapper {
      width: calc(50% - 1rem);
      height: 120px;
    }
  }
  </style>
  
  